import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import { useMediaQuery } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import logo from '../../assets/logo-text-color.png';
import { LineScalePulseOut } from 'react-pure-loaders';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';

import { useLocation, useHistory } from 'react-router-dom';
import { confirmSignup } from '../../services/user';

import { toast } from 'react-toastify'

const useStyles = makeStyles((theme) => ({
  main: {
    flex: 1,
    paddingTop: theme.spacing(4),
    width: '100%',
    overflow: 'auto',/* 'overlay', */
  },
  mainMobile: {
    paddingTop: theme.spacing(0),
    overflow: 'auto',/* 'overlay', */
  },
  root: {
    padding: 0,
  },
  paper: {
    height: '90%',
    width: '35vw',
    margin: 'auto',
  },
  paperMobile: {
    height: '100%',
    width: '100%',
    margin: 'auto',
  },
  position: {
    position: "relative",
    fontFamily: theme.typography.fontFamily,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'unset'
    }
  },

  underline: {
    textDecoration: "underline"

  },
  flip: {
    transform: "rotateY(360deg)",
    transition: "transform 0.7s",
  },
  backFlip: {
    transform: "rotateY(-360deg)",
    transition: "transform 0.7s",
  },
  divRegister: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(7)
  },
  divRegisterMobile: {
    textAlign: "center",
    margin: "0vh"
  },
  textPattern: {
    color: '#000000'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },

}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {' © '}
      <Link color="inherit" href="https://easywork.com.br/">
        Bluesurvey
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function ConfirmaCadastro() {

  let query = useQuery()
  const classes = useStyles();
  const matches = useMediaQuery('(max-width:600px)');
  const history = useHistory();

  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitting(true);

    const token = query.get('token');
    await confirmSignup(token).then(async (res) => {

      setSubmitting(false);
      toast.success('Email confirmado com sucesso.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      history.push('/login')


    }).catch(async (error) => {
      setSubmitting(false);
      toast.error('Algo inesperado ocorreu. Por favor, tente novamente em alguns minutos.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
      .finally(() => {
        setSubmitting(false);
      });

  };

  return (
    <div className={`${classes.main} ${matches ? (`${classes.mainMobile}`) : ''} `}>
      <Container className={classes.root} >
        <Paper
          className={matches ? classes.paperMobile : classes.paper}
          elevation={matches ? 0 : 3}
        >
          <div className={classes.root}>
            <div>
              <img src={logo} width={'100%'} alt="Bluesurvey" />
            </div>
            <form className={classes.form} noValidate onSubmit={handleSubmit} >
              {/* <TextField
                  className={classes.input}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="email"
                  label={<Typography className={classes.textPattern}>Email</Typography>}
                  id="email"
                  autoComplete="current-email"
                  value="teste@gmail.com.br"
                  disabled
              /> */}
              <Button
                type="submit"
                size='large'
                fullWidth
                variant="contained"
                color='primary'
                className={classes.submit}
              >
                {submitting ? (
                  <LineScalePulseOut
                    color={'#BA7100'}
                    loading={submitting}
                  />
                ) : (
                  "Confirmar cadastro"
                )}
              </Button>
            </form>
          </div>
        </Paper>
        <Box mt={1} position="relative">
          <Copyright />
        </Box>
      </Container>
    </div>
  );
}