/* eslint-disable no-restricted-globals */
// ********************************************************************
// IMPORTANTE!
//
// FIREBASE FOI REMOVIDO EM 18/08/2022 E SUBSTITUIDO POR LOCALSTORAGE
// TOKEN FAZ O CONTROLE DE ACESSO E AUTENTICAÇÃO
//
// ********************************************************************

import React, { createContext, useEffect, useRef, useState } from 'react';
import { myProfilePhoto } from '../services/user';
import { useHistory, useLocation } from 'react-router-dom';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {

  const dev = false

  const unload = useRef(false)
  const loaded = useRef(false)
  const isAuthenticated = useRef(false)

  let query = useQuery()

  const history = useHistory();

  const [user, setUser] = useState(window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null)
  const [isLoading, setIsLoading] = useState(false)

  const setPicture = async (picture) => {
    const data = { ...userState, picture: picture }
    setUserState(data)
    window.localStorage.setItem('user', JSON.stringify(data))
  }

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const login = async (data) => {

    const user = {
      ...data,
      isAuthenticated: true
    }
    window.localStorage.setItem('isLogged', true)
    window.localStorage.setItem('user', JSON.stringify(user))
    setUserState(user)
  }

  const logout = async () => {
    isAuthenticated.current = false
    localStorage.setItem('tabsOpened', 1)
    window.localStorage.removeItem('isLogged')
    window.localStorage.removeItem('user')
    setUserState({
      isAuthenticated: false,
      accessToken: ''
    })
  }

  let initial = user || {
    isAuthenticated: false,
    accessToken: ''
  }

  const [userState, setUserState] = useState(initial)
  const value = { userState, setUserState, login, logout, isLoading, setIsLoading, setPicture }

  useEffect(() => {

    if (userState?.isAuthenticated) {
      isAuthenticated.current = Boolean(window.localStorage.getItem('isLogged'))
    }

    if (userState && userState.isAuthenticated && userState.picture === undefined) {
      myProfilePhoto()
        .then(res => {
          setPicture(res.data)
        })

    }

  }, [userState])

  useEffect(() => {

    if (!dev) {

      const handleInvalidToken = e => {
        if (e.key === 'isLogged' && e.oldValue && !e.newValue) {
          logout()
          history.push('/login')
        }
      }

      window.addEventListener('storage', handleInvalidToken)
      return function cleanup() {
        window.removeEventListener('storage', handleInvalidToken)
      }

    }

  }, [logout])

  function clearStorage() {

    if (!dev) {

      let tabsOpened = localStorage.getItem('tabsOpened')
      if (tabsOpened === null || tabsOpened <= 0) {
        logout()

        const action = query?.get('action')
        if (action === 'signup') {
          history.push(window.location.pathname)
        } else if (location.href.includes('acesso')) {
          history.push(window.location.pathname + window.location.search)
        } else {
          history.push('/login')
        }
      }

    }

  }

  window.addEventListener('load', () => {

    if (!dev) {

      if (!loaded.current) {
        clearStorage()
        let tabsOpened = parseInt(localStorage.getItem('tabsOpened')) || 0
        if (isAuthenticated.current) {
          localStorage.setItem('tabsOpened', tabsOpened + 1)
        } else {
          localStorage.setItem('tabsOpened', 1)
        }
        loaded.current = true
      }

    }

  })

  window.addEventListener("beforeunload", function (e) {
    if (!dev) {
      
      if (!unload.current && isAuthenticated.current) {
        let tabsOpened = parseInt(localStorage.getItem('tabsOpened'))
        this.localStorage.setItem('tabsOpened', tabsOpened - 1)
        unload.current = true
      }

    }
  })

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider;

