import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import ReactCardFlip from 'react-card-flip';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import RecuperarSenha from './components/RecuperarSenha';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { useLocation, useHistory } from 'react-router';
import { useRef } from 'react';

//TODO: Melhorar as mensagens de validação.

const useStyles = makeStyles((theme) => ({
    main: {
        flex: 1,
        paddingTop: theme.spacing(4),
        //height:`calc(100% - ${theme.spacing(7)}px)`,
        width: '100%',
        /*  display: 'flex',
         flexDirection: 'column',
         alignItems: 'center', */
        overflow: 'auto',/* 'overlay', */
    },
    mainMobile: {
        paddingTop: theme.spacing(0),
        overflow: 'auto',/* 'overlay', */
    },
    root: {
        padding: 0,

        //marginTop: '3%',
        /*  display: 'flex',
         flexDirection: 'column',
         alignItems: 'center', */
    },
    paper: {
        //marginTop: theme.spacing(8),
        /* display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', */
        height: '90%',
        width: '35vw',
        margin: 'auto',
    },
    paperMobile: {
        //marginTop: theme.spacing(8),
        /* display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', */
        height: '100%',
        width: '100%',
        margin: 'auto',
    },
    position: {
        position: "relative",
        fontFamily: theme.typography.fontFamily,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: 'unset'
        }
    },

    underline: {
        textDecoration: "underline"

    },
    flip: {
        transform: "rotateY(360deg)",
        transition: "transform 0.7s",
    },
    backFlip: {
        transform: "rotateY(-360deg)",
        transition: "transform 0.7s",
    },
    divRegister: {
        textAlign: "center",
        marginTop: theme.spacing(2),
        paddingBottom: theme.spacing(7)
    },
    divRegisterMobile: {
        textAlign: "center",
        margin: "0vh"
    },

}));

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {' © '}
            <Link color="inherit" href="https://easywork.com.br/">
                Bluesurvey
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function LoginPage() {

    const divRef = useRef()

    const [isSignUp, setSignUp] = useState(false);
    const [recuperarSenha, setRecuperarSenha] = useState(false);
    const classes = useStyles();
    const matches = useMediaQuery('(max-width:600px)');
    let query = useQuery();

    const history = useHistory()

    const actions = new Map([["signup", true], ["signin", false]]);

    useEffect(() => {
        if (query.get("recuperarsenha") != null) {
            setRecuperarSenha(true);
        }

        if (query.get("action") !== null && actions.get(query.get("action")) !== undefined) {
            setSignUp(actions.get(query.get("action")))
        }
    }, [query, actions]);

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const changeContent = () => {
        history.push('/')
        // if (isSignUp === true) window.location.href = "/"
        setSignUp(!isSignUp);
        setRecuperarSenha(false);
    }

    const signIn = () => {
        setRecuperarSenha(false);
        setSignUp(false);
    }

    const changeRecuperarSenha = () => {
        setRecuperarSenha(!recuperarSenha);
    }

    useEffect(() => {
        if (divRef.current) {
            divRef.current.scrollTo({ top: 0, behavior: 'smooth' })
        }
    }, [isSignUp])

    return (
        <div ref={divRef} className={`${classes.main} ${matches ? (`${classes.mainMobile}`) : ''} `}>

            <Container className={classes.root} >
                <CssBaseline />
                <div className={`${classes.paper} ${matches ? (`${classes.paperMobile}`) : ''} mob`}>

                    {recuperarSenha
                        ? <RecuperarSenha voltar={signIn} />
                        : <ReactCardFlip isFlipped={isSignUp} flipDirection="horizontal">
                            <SignIn recuperarSenha={changeRecuperarSenha} />

                            <SignUp changeContent={changeContent} query={query} />

                        </ReactCardFlip>
                    }

                </div>
                <div className={`${classes.divRegister} ${matches ? (`${classes.divRegisterMobile}`) : ''} }`}>
                    <Button className={`${classes.position} ${isSignUp ? classes.flip : classes.backFlip} `} onClick={changeContent}>
                        {isSignUp ? (<span>Voltar para <span className={classes.underline}>Login</span></span>) :
                            (
                                <span>Não possui cadastro?&nbsp;<span className={classes.underline}>Clique aqui</span></span>
                            )
                        }
                    </Button>

                    <Box mt={1} position="relative">
                        <Copyright />
                    </Box>
                </div>
            </Container>
        </div>
    );
}