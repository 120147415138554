import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { StepTwoFromUserType } from './components/StepTwoFromUserType';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Grid, Button, Box, Dialog, Step, StepLabel, Stepper, Typography, DialogContent, TextareaAutosize, DialogActions, DialogContentText, FormControl, Select, MenuItem, TextField } from '@material-ui/core';
import { putResponseFinish, postResponseStart, acessedMyResearchByIdResearch } from '../../services/response';
import { finishMyResearchByIdResearch, getByIdResearch, resetMyResearchByIdResearch, stopMyResearchByIdResearch } from '../../services/research';
import { Check, UpdateOutlined, AttachMoneyOutlined, TodayOutlined, NavigateNext, NavigateBefore, PlayArrow, Pause, Help } from '@material-ui/icons';
import { toast } from 'react-toastify';
import Alert from '@material-ui/lab/Alert';
import clsx from 'clsx';
import moment from 'moment';
import { UserContext } from '../../providers/UserProvider';
import Countdown from 'react-countdown';
import { apiResponse } from '../../services/api';


const useStyles = makeStyles((theme) => ({
  titleText: {
    color: '#111640',
    fontSize: '18px',
    fontWeight: 600,
    maxWidth: 'fit-content',
    margin: '15px auto'
  },
  titleTextDados: {
    color: '#111640',
    fontSize: '16px',
    fontWeight: 600,
    maxWidth: 'fit-content',
    margin: '0 auto 5px 0'
  },
  titleTextCustom: {
    color: '#111640',
    fontSize: '18px',
    fontWeight: 600,
    maxWidth: '100%',
    wordWrap: 'break-word'
  },
  iconCustom: {
    color: '#576B77',
    fontWeight: 600,
    fontSize: '1.3rem',
    marginRight: '10px'
  },
  textPattern: {
    textAlign: 'justify',
    color: '#576B77',
    fontWeight: 400,
    fontSize: '14px',
    maxWidth: '100%',
    wordWrap: 'break-word',
    margin: '0 auto 5px 0'
  },
  textPatternBlue: {
    textAlign: 'justify',
    color: '#1EADB5',
    fontWeight: 600,
    fontSize: '14px',
    maxWidth: '100%',
    wordWrap: 'break-word',
    margin: 'auto'
  },
  iconPattern: {
    color: '#576B77',
    fontWeight: 400,
    fontSize: '18px',
    maxWidth: '100%',
    wordWrap: 'break-word',
    margin: '0 10px 0 0'
  },
  margin: {
    margin: '24px 0'
  },
  margin2: {
    margin: '24px 32px'
  },
  countDownTime: {
    color: '#111640',
    fontSize: '48px',
    fontWeight: 600,
    maxWidth: '100%',
    wordWrap: 'break-word'
  },
  button: {
    margin: '24px 0 ',
    width: 'fit-content',
    boxShadow: 'none',
    fontWeight: 600,
  },
  buttonInfo: {
    backgroundColor: '#F0F0F0',
    color: '#121949',
    margin: '8px 0 ',
    fontWeight: 600,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#F0F0F0',
      color: '#121949',
    }
  },
  buttonGreen: {
    backgroundColor: '#199D65',
    color: '#FFFFFF',
    margin: '24px 0 ',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#199D65',
      color: '#FFFFFF',
    }
  },
  footerCardText: {
    margin: '40px auto',
    color: '#576B77',
  },
  alertCustom: {
    minWidth: '100%',

    '& .MuiAlert-message': {
      fontSize: '14px',
      fontWeight: 500
    },
    '& .MuiAlert-icon': {
      margin: 'auto 10px auto 0'
    }
  },
  textIntro: {
    color: '#111640',
    fontSize: '15px',
    fontWeight: 600,
    maxWidth: '100%',
    margin: '0  0 5px 0 '
  },
  select: {
    backgroundColor: '#fff',
  },
  input: {
    height: '100%',
    width: '100%',
    minWidth: '100%',
    border: 'none',
    fontFamily: 'Montserrat',
    color: '#333333',
    padding: '13.5px 10px',
  },
  fieldsetInput: {
    marginTop: '15px',
    minWidth: '100%',
    backgroundColor: '#fff',
    animationName: 'onAutoFillCancel',
    border: '1px solid #c4c4c4',
    borderRadius: '4px',
    padding: '0 8px',
    '&:hover': {
      borderColor: 'rgba(0, 0, 0, 0.87)'
    },
  },
  legendPattern: {
    color: '#000000',
    fontSize: '14px',
    padding: '0 5px'
  },
}));

const useCustomStepIconStyles = makeStyles({
  root: {
    color: '#576B77',
    display: 'flex',
    alignItems: 'center',
  },
  active: {
    color: '#FAFCFC',
  },
  circle: {
    width: 28,
    height: 28,
    borderRadius: 15,
    backgroundColor: '#1EADB5',
    textAlign: "center",
    fontSize: 13,
    padding: 5
  },
  circleDefault: {
    width: 28,
    height: 28,
    borderRadius: 15,
    backgroundColor: '#F0F0F0',
    color: '#576B77',
    textAlign: "center",
    fontSize: 13,
    padding: 5
  },
  completed: {
    width: 28,
    height: 28,
    borderRadius: 15,
    backgroundColor: '#1EADB5',
    color: '#ffffff',
    textAlign: "center",
    fontSize: 13,
    padding: 5
  },
});

function CustomStepIcon(props) {
  const classes = useCustomStepIconStyles();
  const { active, completed } = props;
  const { userState } = useContext(UserContext);

  const labelPesq = {
    1: '1',
    2: '2',
    3: '3',
  };

  const labelResp = {
    1: '1',
    2: '2',
    3: '3',
    4: '4'
  };


  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : active ? <div className={classes.circle}>{userState?.isResearcher ? labelPesq[String(props.icon)] : labelResp[String(props.icon)]}</div> : <div className={classes.circleDefault}>{userState?.isResearcher ? labelPesq[String(props.icon)] : labelResp[String(props.icon)]}</div>}
    </div>
  );
}

export default function SurveyForMemberPage() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const quiz_id = JSON.parse(params.id)
  const [activeStep, setActiveStep] = useState(location.state.stepId);
  const [createdAt, setCreatedAt] = useState(location?.state?.created_at || "");
  const [responseId, setResponseId] = useState(location.state.responseId || "");
  const [code, setCode] = useState();
  const [research, setReasearch] = useState({});
  const { userState } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [faleConosco, setFaleConosco] = useState(false)
  const [faleConoscoMensagem, setFaleConoscoMensagem] = useState('')
  const [faleConoscoTipo, setFaleConoscoTipo] = useState('RESEARCHER')
  const [modalOpen, setModalOpen] = useState(false)
  let limitCharsJustification = 15
  const [remainCharsJustification, setRemainCharsJustification] = useState(limitCharsJustification);
  const [justification, setJustification] = useState("");
  const [responseStarted, setResponseStarted] = useState()
  const [responseFinished, setResponseFinished] = useState()

  const steps = getSteps();

  const [countdownDate, setCountdownDate] = useState()

  // useEffect(() => {
  //   if (research?.maximumResponseTime) {
  //     var countDate = Date.now() + research.maximumResponseTime * 60000
  //     setCountdownDate(countDate)
  //   }

  // }, [research])

  function getSteps() {
    if (userState?.isResearcher) return ['Iniciar', 'Avaliar', 'Finalizar']
    return ['Inscrever', 'Iniciar', 'Código', 'Finalizar'];
  }

  useEffect(() => {
    setActiveStep(location.state.stepId)
  }, [location]);

  useEffect(() => {
    loadReasearch()
  }, [quiz_id]);


  async function loadReasearch() {
    await getByIdResearch(quiz_id)
      .then(response => {
        setReasearch(response.data)
        setCountdownDate(response.data.accessed_date)
      })
  }

  useEffect(() => {
    if (!userState.isResearcher) {
      if (responseId) {
        apiResponse.get(`api/responses/get/${responseId}`)
          .then(res => {
            setResponseStarted(res.data.accessedAt)
          })
          .catch(err => {
          })
      }
    }
  }, [userState, responseId])

  const handleCancel = (event) => {
    event.preventDefault();
    history.goBack();
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleModalOpen = () => {
    setModalOpen(true)
  }

  const handleJustification = () => {
    apiResponse.post(`api/responses/mailMessage`, {
      message: justification,
      researchId: params.id
    })
      .then((response) => {
        setModalOpen(false)
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch(err => {
        setModalOpen(false)
        if (err.response?.data?.status !== 400) {
          toast.error(err.response?.data?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .finally(() => {
        setModalOpen(false)
      })
  }

  const handleModalClose = () => {
    setModalOpen(false)
  }

  const handleViewInfo = (event) => {
    event.preventDefault();

    setOpen(true);
  }


  const handleFaleConosco = (event) => {
    event.preventDefault();

    setFaleConosco(true);
  }

  const handleCloseFaleConosco = () => {
    setFaleConosco(false);
  };

  const handleChangeCode = (code) => {
    setCode(code)
  }

  const handleSubmitAcessed = async (event, id) => {
    event.preventDefault();

    await acessedMyResearchByIdResearch(id)
      .then(response => {
        if (response.status === 200) {
          if (response.data.success) {
            toast.success(response.data.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setResponseStarted(response.data.accessedAt)
            setTimeout(() => {
              window.open(research?.link, '_blank', 'noopener,noreferrer')
            })
            setActiveStep((prevActiveStep) => prevActiveStep + 1)
          } else {
            toast.error(response.data.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      })
      .catch(error => {
        if (error.response?.data?.status !== 400) {
          toast.error('Algo inesperado ocorreu. Por favor, tente atualizar a página em alguns minutos.!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
  }

  useEffect(() => {

    if (research?.maximumResponseTime) {

      let countDate = Date.now() + research?.maximumResponseTime * 60000
      if (responseStarted) {
        countDate = new Date(Date.parse(responseStarted) + research?.maximumResponseTime * 60000).toISOString()
        countDate = countDate.slice(0, -1)
      }

      setCountdownDate(countDate)
    }

  }, [responseStarted, research])

  const handleSubmitStart = async (event) => {
    event.preventDefault();

    await postResponseStart(quiz_id)
      .then(async response => {
        if (response.status === 200) {
          toast.success('Pesquisa iniciada!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setCreatedAt(response.data.createdAt)
          setResponseId(response.data.id)
          setActiveStep((prevActiveStep) => prevActiveStep + 1)
        }
      })
      .catch(error => {
        if (error.response?.data?.status !== 400) {
          toast.error('Algo inesperado ocorreu. Por favor, tente atualizar a página em alguns minutos.!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const handleSubmitFinish = async (event, id) => {
    event.preventDefault();

    await finishMyResearchByIdResearch(id)
      .then(response => {
        if (response?.data.success) {
          toast.success(response?.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          loadReasearch()
        } else if (response?.data.status === 401) {
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }).catch(error => {
        const { data, status } = error.response
        if (status === 401) {
          toast.error(data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        else if (status !== 400) {
          toast.error('Algo inesperado ocorreu. Por favor, tente novamente em alguns minutos.!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
  }

  const handleSubmitReset = async (event, id) => {

    event.preventDefault();
    await resetMyResearchByIdResearch(id)
      .then(response => {
        if (response?.data.success) {
          toast.success('Pesquisa reiniciada com sucesso!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          loadReasearch()
        } else if (response?.data.status === 401) {
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }).catch(error => {
        if (error.response?.data?.status !== 400) {
          toast.error('Algo inesperado ocorreu. Por favor, tente novamente em alguns minutos.!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })

  }

  const handleSubmitFaleConosco = async (e) => {

    apiResponse.post(`api/responses/contact`, {
      researchId: research.id,
      mailDestiny: faleConoscoTipo,
      message: faleConoscoMensagem
    })
      .then(res => {
        toast.success('Mensagem enviada!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setFaleConosco(false)
      })
      .catch(error => {
        if (error.response?.data?.status !== 400) {
          toast.error('Algo inesperado ocorreu. Por favor, tente novamente em alguns minutos.!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        setFaleConosco(false)
      })

  }

  const handleSubmitPause = async (event, id) => {
    event.preventDefault();

    await stopMyResearchByIdResearch(id)
      .then(response => {
        if (response?.data.success) {
          toast.success(response?.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          loadReasearch()
        } else if (response?.data.status === 401) {
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }).catch(error => {
        if (error.response?.data?.status !== 400) {
          toast.error('Algo inesperado ocorreu. Por favor, tente novamente em alguns minutos.!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })

  }

  // Fazer validação
  const handleSubmitCode = async (event, id) => {
    event.preventDefault();

    const data = {
      code: code
    }

    await putResponseFinish(id, data)
      .then(response => {
        if (response.data.success) {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setResponseFinished(true)
          setActiveStep((prevActiveStep) => prevActiveStep + 1)
        }
      })
      .catch(error => {
        if (error.response?.data?.status !== 400) {
          toast.error('Algo inesperado ocorreu. Por favor, tente atualizar a página em alguns minutos.!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
  }

  return (
    activeStep !== -1 ?
      <React.Fragment>

        {/* Modal */}
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={'paper'}
          fullWidth={true}
          maxWidth={'sm'}
        >
          <Box component={'h3'} style={{ color: '#111640', padding: '24px 24px 8px 24px' }}>Instruções:</Box>
          <DialogContent>
            <DialogContentText style={{ color: '#576B77', fontSize: '16px', lineBreak: 'anywhere' }}>
              {research?.description}
            </DialogContentText>
          </DialogContent>
        </Dialog>
        {/* End Modal */}

        <Dialog
          open={faleConosco}
          onClose={handleCloseFaleConosco}
          scroll={'paper'}
          fullWidth={true}
          maxWidth={'sm'}
        >
          <Box component={'h3'} style={{ color: '#111640', padding: '24px 24px 8px 24px' }}>Fale conosco</Box>
          <DialogContent>
            <DialogContentText style={{ color: '#576B77', fontSize: '16px', lineBreak: 'anywhere' }}>
              <Grid container>
                <Grid item xs={12}>
                  <Select
                    variant="outlined"
                    onChange={(event, item) => {
                      setFaleConoscoTipo(item.props.value)
                    }}
                    className={classes.select}
                    defaultValue='RESEARCHER'
                    fullWidth
                  >
                    <MenuItem value='RESEARCHER' style={{ color: '#000' }}>Pesquisador</MenuItem>
                    <MenuItem value='SUPPORT' style={{ color: '#000' }}>Suporte</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <fieldset id="fieldsetDate" className={classes.fieldsetInput}>
                    <legend className={classes.legendPattern}>Mensagem</legend>
                    <TextareaAutosize
                      fullWidth
                      aria-label="Mensagem"
                      rowsMin="8"
                      name="mensagem"
                      className={classes.input}
                      onChange={(event) => { setFaleConoscoMensagem(event.target.value) }}
                    />
                  </fieldset>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center' }}
                >
                  <Button
                    className={classes.button}
                    type="button"
                    size='large'
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={e => handleSubmitFaleConosco(e)}
                  >
                    Enviar
                  </Button>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
        </Dialog>

        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          sm={12} xs={12} md={12} lg={12}
        >
          <Box component="div" className={'divPaperContent'}>
            <Grid item sm={12} xs={12} md={12} lg={12}>
              <Typography className={classes.titleText}>
                {userState.isResearcher ? research?.name : 'RESPONDER PESQUISA'}
              </Typography>
              <form noValidate>
                <Grid item sm={12} xs={12} md={12} lg={12} className={`cardForm paddingTabs`}>
                  <Stepper style={{ padding: '36px 0' }} activeStep={activeStep} nonLinear>
                    {steps.map((label, index) => (
                      <Step key={label} active={index <= activeStep ? true : false} completed={index < activeStep || ((activeStep === 2 || activeStep === 3) && ['APPROVED', 'REFUSED', 'EXPIRED'].includes(location.state.statusResponse)) || research?.status === 'FINISHED' ? true : false}>
                        <StepLabel StepIconComponent={CustomStepIcon}>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>

                  {!userState.isResearcher ? <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="flex-start"
                    className={classes.elementsMargin}
                  >
                    <Typography component="h3" className={classes.titleTextCustom} >
                      {research?.name}
                    </Typography>

                    <Typography component="h3" className={classes.textPattern} >
                      de {research?.researcherName}
                    </Typography>
                  </Grid> : ''}

                  {activeStep === 0 && !userState?.isResearcher ? (
                    <React.Fragment>

                      <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                        className={classes.margin}
                      >
                        <Grid item>
                          <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                          >
                            <UpdateOutlined className={classes.iconCustom} />
                            <Typography variant="body2" color="textSecondary" className={classes.textPattern}>
                              {research?.maximumResponseTime} min
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid item>
                          <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                          >
                            <TodayOutlined className={classes.iconCustom} />
                            <Typography variant="body2" color="textSecondary" className={classes.textPattern}>
                              {moment(research?.endDate).format('DD/MM/YYYY')}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid item>
                          <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                          >
                            <AttachMoneyOutlined className={classes.iconCustom} />
                            <Typography variant="body2" color="textSecondary" className={classes.textPattern}>
                              {new Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(research?.payAmount)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="flex-start"
                        className={classes.margin}
                      >
                        <Typography variant="body2" color="textSecondary" className={classes.textIntro}>
                          Instruçoes:
                        </Typography>
                        <Typography variant="body2" color="textSecondary" className={classes.textPattern}>
                          {research?.description}
                        </Typography>
                      </Grid>
                    </React.Fragment>

                  ) : activeStep === 1 && !userState?.isResearcher ? (

                    <React.Fragment>
                      <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        className={classes.margin}
                      >
                        <Grid item>
                          {research?.maximumResponseTime && <Countdown
                            autoStart={false}
                            date={Date.now() + research?.maximumResponseTime * 60000}
                            daysInHours={true}
                            className={classes.countDownTime} />
                          }
                        </Grid>

                        <Grid item className={classes.margin}>
                          <Typography variant="body2" color="textSecondary" className={classes.textPattern}>
                            <ul className={classes.margin2}>
                            <li>
                              Será pedido pelo pesquisador seu código de respondente, que está no cabeçalho da página à direita. Copie e cole o código no campo sugerido pelo pesquisador. Este código ajudará a identificar você como participante da pesquisa.
                            </li>
                            <li>
                              Ao final da pesquisa, você acessará um código para colar no campo de “Código da pesquisa“, que aparece após você se inscrever na pesquisa. Este código ajudará a identificar você como respondente da pesquisa para verificação da qualidade das suas respostas.
                            </li>
                            <li>
                              Certifique-se de deixar esta janela aberta ao concluir a pesquisa. Quando terminar, você retornará a esta página para colar o código no campo correto.
                            </li>
                          </ul>
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Button
                          className={`${classes.buttonInfo}`}
                          type="button"
                          size='large'
                          color="secondary"
                          onClick={handleViewInfo}
                        >
                          VER INSTRUÇÕES
                        </Button>
                      </Grid>
                    </Grid>
                    </React.Fragment>

                ) : (activeStep === 2 && research?.researcherId) || (activeStep === 1 && research?.researcherId) ? (

                <React.Fragment>
                  <StepTwoFromUserType handleChangeCode={handleChangeCode} research={research} createdAt={createdAt} countdownDate={countdownDate} code={code} responseId={responseId} />
                </React.Fragment>

                ) : activeStep === 3 ? (
                <React.Fragment>
                  <Dialog
                    open={modalOpen}
                    onClose={handleModalClose}
                    scroll={'paper'}
                    fullWidth={true}
                    maxWidth={'sm'}
                  >
                    <Box component={'h3'} style={{ color: '#111640', padding: '24px 24px 8px 24px' }}>Justificativa</Box>
                    <DialogContent>
                      <DialogContentText style={{ color: '#576B77', fontSize: '16px' }}>
                        Utilize o campo abaixo para explicar o porquê você discorda do motivo da recusa da resposta. O pesquisador receberá essa informação e poderá retornar em seu e-mail.
                      </DialogContentText>

                      <TextareaAutosize className="textAreaSize" rowsMin={6} minLength={0} maxLength={600} onChange={(e) => setJustification(e.target.value)} />

                      <Typography className={classes.colorGray} component="p">
                        Necessários {remainCharsJustification} caracteres.
                      </Typography>
                    </DialogContent>
                    <DialogActions style={{ padding: "8px 24px 24px" }}>
                      <Button
                        className={`buttonCancel buttonModal`}
                        type="button"
                        size='large'
                        color="secondary"
                        startIcon={<NavigateBefore />}
                        onClick={handleModalClose}
                      >
                        Voltar
                      </Button>
                      <Button
                        className={`buttonModal`}
                        type="button"
                        size='large'
                        variant="contained"
                        color="primary"
                        endIcon={<NavigateNext />}
                        onClick={handleJustification}
                      >
                        Enviar
                      </Button>
                    </DialogActions>
                  </Dialog>
                  {(location.state.statusResponse === 'PENDING' || responseFinished) ?
                    <Typography align="center" className={classes.footerCardText}>
                      Obrigado por participar! Agora é só aguardar a confirmação do pesquisador para receber sua recompensa.
                    </Typography> :

                    location.state.statusResponse === 'EXPIRED' ?
                      <Typography align="center" className={classes.footerCardText}>
                        Pesquisa expirada!
                      </Typography> :

                      location.state.statusResponse === 'APPROVED' ? (
                        <React.Fragment>
                          <Typography align="center" className={classes.footerCardText}>
                            Essa pesquisa já foi finalizada e sua participação aprovada. A recompensa foi creditada em sua carteira.
                          </Typography>
                          <Box width={'100%'} display={'flex'}>
                            <Button
                              className={classes.button}
                              style={{ margin: '0 auto 36px auto', padding: '8px 22px', fontWeight: '600' }}
                              type="button"
                              size='large'
                              variant="contained"
                              color="primary"
                              onClick={() => history.push('/financial-info')}
                            >
                              Confira
                            </Button>
                          </Box>
                        </React.Fragment>
                      ) : location.state.statusResponse === 'REFUSED' ?
                        <Typography align="center" className={classes.footerCardText}>
                          Essa pesquisa já foi finalizada, porém sua participação não foi aprovada.<br />Você pode enviar uma resposta ao criador usando o botão abaixo.<br />Mensagem do pesquisador:<br />

                          {location?.state?.reason && location?.state?.reason !== "" ? <Alert className={classes.alertCustom} style={{ marginTop: '30px' }} severity="error">{location?.state?.reason}</Alert> : ''}

                          <Button
                            className={classes.button}
                            style={{ padding: '8px 22px', fontWeight: '600' }}
                            type="button"
                            size='large'
                            variant="contained"
                            color="primary"
                            onClick={handleModalOpen}
                          >
                            ENVIAR MENSAGEM AO PESQUISADOR
                          </Button>
                        </Typography> : ''}
                </React.Fragment>
                  ) : ''}
            </Grid>

            <Grid item sm={12} xs={12} md={12} lg={12} className={'footerButtonsTabs'} >
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid item >
                  <Button
                    className={`buttonCancel ${classes.button}`}
                    type="button"
                    size='large'
                    color="secondary"
                    startIcon={<NavigateBefore />}
                    onClick={handleCancel}
                  >
                    Voltar
                  </Button>
                </Grid>
                {activeStep === 1 && userState?.isResearcher ? <Grid item >
                  <Button
                    className={classes.button}
                    type="button"
                    size='large'
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={research.status === "FINISHED" ? true : false}
                    startIcon={research.status === "STOPPED" ? <PlayArrow /> : <Pause />}
                    onClick={research.status === "STOPPED" ? (e) => handleSubmitReset(e, research.id) : (research.status === "PENDING" || research.status === "PUBLISHED") ? (e) => handleSubmitPause(e, research.id) : () => ('')}
                  >
                    {(research.status === "PENDING" || research.status === "PUBLISHED") ? 'Pausar' : research.status === "STOPPED" ? 'Reiniciar' : 'Pausar'}
                  </Button>
                </Grid> : ''}
                <Grid item>
                  {!userState?.isResearcher && <Button
                    className={classes.button}
                    type="button"
                    size='large'
                    fullWidth
                    variant="contained"
                    color="secondary"
                    startIcon={<Help />}
                    style={{
                      marginRight: 10
                    }}
                    onClick={handleFaleConosco}
                  >
                    Fale conosco
                  </Button>}
                  {activeStep === 0 && !userState?.isResearcher ? <Button
                    className={classes.button}
                    type="button"
                    size='large'
                    fullWidth
                    variant="contained"
                    color="primary"
                    endIcon={<NavigateNext />}
                    onClick={e => handleSubmitStart(e)}
                  >
                    Inscrever
                  </Button> :

                    activeStep === 1 && !userState?.isResearcher ? <Button
                      className={classes.button}
                      type="button"
                      size='large'
                      fullWidth
                      variant="contained"
                      color="primary"
                      endIcon={<NavigateNext />}
                      onClick={(e) => handleSubmitAcessed(e, responseId)}
                    >
                      ACESSAR PESQUISA
                    </Button> :

                      (activeStep === 2 || activeStep === 1 && userState?.isResearcher) ? <Button
                        className={userState?.isResearcher ? classes.buttonGreen : classes.button}
                        type="button"
                        size='large'
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={research?.status === "FINISHED" ? true : false}
                        endIcon={<NavigateNext />}
                        onClick={userState?.isResearcher ? (e) => handleSubmitFinish(e, research.id) : (e) => handleSubmitCode(e, responseId)}
                      >
                        {research?.status === "FINISHED" ? 'FINALIZADA' : 'FINALIZAR'}
                      </Button> : ''}
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Box>
        </Grid >
      </React.Fragment >
      :
  <React.Fragment>
  </React.Fragment>
  )
}
