import { apiResponse } from './api';

export const bankTransfered = async (id) => {
    return await apiResponse.put(`api/responses/bankTransfered/${id}`).then(response => {
        return response
    });
}

export const batchBankTransfered = async (ids) => {
    return await apiResponse.put(`api/responses/batchBankTransfered`, { batchTransfered: ids }).then(response => {
        return response
    });
}

export const myWithdraw = async (value) => {
    return await apiResponse.get(`api/responses/myWithdraw`).then(response => {
        return response
    });
}

export const allWithdraw = async (value) => {
    return await apiResponse.get(`api/responses/allWithdraw`).then(response => {
        return response
    });
}

export const pendingWithdraw = async (value) => {
    return await apiResponse.get(`api/responses/pendingWithdraw`).then(response => {
        return response
    });
}

export const askFunds = async (value) => {
    return await apiResponse.post(`api/responses/askFunds/${value}`).then(response => {
        return response
    });
}

export const confirmAskFunds = async (value) => {
    return await apiResponse.put(`api/responses/confirmAskFunds`, {
        code: value
    }).then(response => {
        return response
    });
}


export const getAllResponses = async () => {
    return await apiResponse.get('api/responses/myresponsesfull').then(response => {
        return response
    });
}

export const getByIdResearchResponses = async (uid) => {
    return await apiResponse.get(`api/responses/${uid}`).then(response => {
        return response
    });
}

export const postResponseStart = async (id) => {
    const data = {
        "researchId": id
    }
    return await apiResponse.post('api/responses/start', data).then(response => {
        return response
    });
}

export const putResponseFinish = async (id, data) => {
    return await apiResponse.put('api/responses/finish/' + id, data).then(response => {
        return response
    });
}

export const putResponseApprovaRefused = async (data) => {
    return await apiResponse.put('api/responses/approval', data).then(response => {
        return response
    });
}

export const putResponsesMultiple = async (data) => {
    return await apiResponse.put('api/responses/batchapproval', data).then(response => {
        return response
    });
}

export const acessedMyResearchByIdResearch = async (id) => {
    //console.log("acessedMyResearchByIdResearch " + id)
    const data = {
        "accessed": true,
        "responseId": id,
        "accessed_date": new Date().toISOString()
    }
    return await apiResponse.put(`api/responses/accessed`, data).then(response => {
        return response
    });
}