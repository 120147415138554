import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import { useMediaQuery } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import logo from '../../assets/logo-text-color.png';
import { LineScalePulseOut } from 'react-pure-loaders';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { redefineUserPassword } from '../../services/user';
import { toast } from 'react-toastify'
import { useHistory, useLocation } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    main: {
        flex: 1,
        paddingTop: theme.spacing(4),
        width: '100%',
        overflow: 'auto',/* 'overlay', */
    },
    mainMobile: {
        paddingTop: theme.spacing(0),
        overflow: 'auto',/* 'overlay', */
    },
    root: {
        padding: 0,
    },
    paper: {
        height: '90%',
        width: '35vw',
        margin: 'auto',
    },
    paperMobile: {
        height: '100%',
        width: '100%',
        margin: 'auto',
    },
    position: {
        position: "relative",
        fontFamily: theme.typography.fontFamily,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: 'unset'
        }
    },

    underline: {
        textDecoration: "underline"

    },
    flip: {
        transform: "rotateY(360deg)",
        transition: "transform 0.7s",
    },
    backFlip: {
        transform: "rotateY(-360deg)",
        transition: "transform 0.7s",
    },
    divRegister: {
        textAlign: "center",
        marginTop: theme.spacing(2),
        paddingBottom: theme.spacing(7)
    },
    divRegisterMobile: {
        textAlign: "center",
        margin: "0vh"
    },
    textPattern: {
        color: '#000000'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },

}));

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {' © '}
            <Link color="inherit" href="https://easywork.com.br/">
                Bluesurvey
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function TrocarSenha(props) {

    let query = useQuery()

    const classes = useStyles();
    const matches = useMediaQuery('(max-width:600px)');

    const [submitting, setSubmitting] = useState(false);
    const [password, setPassword] = useState(false);
    const [confirmaPassword, setConfirmaPassword] = useState(false);
    const history = useHistory();

    const handleSubmit = async (event) => {
        event.preventDefault();

        setSubmitting(true);

        if (password !== confirmaPassword) {
            toast.error('As senhas não conferem.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        await redefineUserPassword({
            code: query.get('token'),
            password: password
        }).then(async (res) => {

            setSubmitting(false);
            toast.success('Senha alterada com sucesso.', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            history.push('/login')

        }).catch(async (error) => {
            setSubmitting(false);
            toast.error('Algo inesperado ocorreu. Por favor, tente novamente em alguns minutos.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        })
            .finally(() => {
                setSubmitting(false)
            });

    };

    return (
        <div className={`${classes.main} ${matches ? (`${classes.mainMobile}`) : ''} `}>
            <Container className={classes.root} >
                <Paper
                    className={matches ? classes.paperMobile : classes.paper}
                    elevation={matches ? 0 : 3}
                >
                    <div className={classes.root}>
                        <form className={classes.form} noValidate onSubmit={handleSubmit}>
                            <TextField
                                className={classes.input}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label={<Typography className={classes.textPattern}>Senha</Typography>}
                                type="password"
                                id="register-password"
                                autoComplete="current-password"
                                onChange={event => setPassword(event.target.value)}
                            />
                            <TextField
                                className={classes.input}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label={<Typography className={classes.textPattern}>Confirmar senha</Typography>}
                                type="password"
                                id="register-password"
                                autoComplete="current-password"
                                onChange={event => setConfirmaPassword(event.target.value)}
                            />
                            <Button
                                type="submit"
                                size='large'
                                fullWidth
                                variant="contained"
                                color='primary'
                                className={classes.submit}
                            >
                                {submitting ? (
                                    <LineScalePulseOut
                                        color={'#BA7100'}
                                        loading={submitting}
                                    />
                                ) : (
                                    "Trocar senha"
                                )}
                            </Button>
                        </form>
                    </div>
                </Paper>
                <Box mt={1} position="relative">
                    <Copyright />
                </Box>
            </Container>
        </div>
    );
}