import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { LineScalePulseOut } from 'react-pure-loaders';
import { toast } from 'react-toastify';
import TextField from '@material-ui/core/TextField';
import { Tooltip } from '@material-ui/core';
import * as yup from 'yup';
import IntlCurrencyInput from "react-intl-currency-input"
import { generatePixQRCode, myTransaction } from '../../../services/research';
import Countdown from 'react-countdown';
import { myProfileUser, myProfileUserUpdate } from '../../../services/user';

let schema = yup.object().shape({
  valor_total: yup.string().required(),
});

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 18,
    fontWeight: 600,
    color: '#111640',
    marginTop: '1em',
    marginBottom: '1em',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    alignSelf: 'center',
    width: '100%'
  },
  alertCustom: {
    minWidth: '100%',
    marginBottom: '35px',
    textAlign: 'justify',

    '& .MuiAlert-message': {
      fontSize: '14px',
      fontWeight: 500
    },
    '& .MuiAlert-icon': {
      margin: 'auto 10px auto 0'
    }
  },
  button: {
    margin: '0 auto',
    fontWeight: 600,
    width: 350
  },
  textPattern: {
    color: '#576B77',
    fontSize: '16px',
    maxWidth: '100%',
    wordWrap: 'break-word',
  },
  fieldsetInput: {
    minWidth: '100%',
    backgroundColor: '#fff',
    animationName: 'onAutoFillCancel',
    border: '1px solid #c4c4c4',
    borderRadius: '4px',
    padding: '0 8px',
    '&:hover': {
      borderColor: 'rgba(0, 0, 0, 0.87)'
    },
    margin: '15px 0'
  },
  fieldsetCustom: {
    border: '2px solid #30bcb6 !important'
  },
  removeConfigInput: {
    height: '100%',
    width: '100%',
    minWidth: '100%',
    border: 'none',
    fontFamily: 'Montserrat',
    color: '#44444',
    padding: '13.5px 10px',
  },
  legendPattern: {
    color: '#576B77',
    fontSize: '15px',
    padding: '0 30px 0 3px',
    fontWeight: 400,
  },
  countDownTime: {
    color: '#111640',
    fontSize: '16px',
    fontWeight: 400,
    maxWidth: '100%',
    wordWrap: 'break-word'
  },
}));

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
}


export default function CreditPixTab(props) {
  const classes = useStyles();

  const inputChangeHandler = (event, value) => {
    let obj = { [event.target.name]: value ? value : event.target.value }
    setPayment({ ...payment, ...obj })
  };

  const [errors, setErrors] = useState({})
  const [qrCodeExpired, setQrCodeExpired] = useState(false)
  const [countdownDate, setCountdownDate] = useState()
  const [confirmed, setConfirmed] = useState(false)
  const [qrCode, setQrCode] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const [payment, setPayment] = useState({
    documento: '',
    nome: '',
    valor_total: '',
    rua: '',
    numero: '',
    cep: '',
    bairro: '',
    pais: 'Brasil' // no crédito talvez colocar campo para informar - LEMBRAR!!!! TIRAR DÚVIDA COM SAFE2PAY
  })
  const [profile, setProfile] = useState()
  const [profileLoaded, setProfileLoaded] = useState(false)

  useEffect(() => {
    const getMyProfile = async () => {
      await myProfileUser()
        .then(response => {
          setProfile({
            documento: response.data.cadastroNacional || payment.documento,
            nome: response.data.name || payment.nome,
            rua: response.data.street || payment.rua,
            numero: response.data.number || payment.numero,
            cep: response.data.cep || payment.cep,
            bairro: response.data.neighborhood || payment.bairro,
          })
        })
        .finally(() => {
          setProfileLoaded(true)
        })
    }

    if (!profileLoaded) {
      getMyProfile()
    }
  }, [])

  useEffect(() => {

    var pixInterval = null

    if (qrCode) {

      setCountdownDate(Date.now() + 600000)

      pixInterval = setInterval(() => {

        if (qrCode) {
          const id = qrCode.id
          myTransaction(id)
            .then(res => {
              if (res.data.status === 3) {

                toast.success('Pix realizado com sucesso!', {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                })

                setConfirmed(true)

                clearInterval(pixInterval)
              }
            })
            .catch(err => {

            })

        }

      }, 20000)

    }

    return () => clearInterval(pixInterval)

  }, [qrCode])

  const handlePayment = async (event) => {
    event.preventDefault();
    setSubmitting(true)

    const campoObrigatorio = 'Campo obrigatório'

    let isValidForm = await schema.isValid(payment)

    if (!profileLoaded) {
      let errorsDict = {}

      if (!payment.documento || payment.documento.trim().length === 0)
        errorsDict.documento = campoObrigatorio

      if (!payment.nome || payment.nome.trim().length === 0)
        errorsDict.nome = campoObrigatorio

      if (!payment.cep || payment.cep.trim().length === 0)
        errorsDict.cep = campoObrigatorio

      if (!payment.rua || payment.rua.trim().length === 0)
        errorsDict.rua = campoObrigatorio

      if (!payment.numero || payment.numero.trim().length === 0)
        errorsDict.numero = campoObrigatorio

      if (!payment.bairro || payment.bairro.trim().length === 0)
        errorsDict.bairro = campoObrigatorio

      if (Object.keys(errorsDict).length > 0) {
        setErrors(errorsDict)
        isValidForm = false
      }

      myProfileUserUpdate({
        street: payment.rua,
        number: payment.numero,
        cep: payment.cep,
        neighborhood: payment.bairro,
      })
    }

    if (!isValidForm) {
      setSubmitting(false)
      return toast.error('Campos não podem ser vazios!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    const creditos = payment.valor_total

    const transaction = {
      amount: parseFloat(creditos),
      name: profile?.nome || payment.nome,
      document: profile?.documento || payment.documento,
      // Address
      zipCode: profile?.cep || payment.cep,
      street: profile?.rua || payment.rua,
      number: profile?.numero || payment.numero,
      complement: profile?.complemento || payment.complemento,
      district: profile?.bairro || payment.bairro,
      cityName: profile?.cidade || payment.cidade,
      stateInitials: profile?.estado || payment.estado,
      countryName: payment.pais
      // End Address
    }

    generatePixQRCode(transaction)
      .then(result => {
        const { data } = result
        if (data.success) {

          setQrCode({
            id: data.idTransaction,
            img: data.qrCode,
            key: data.key
          })

        } else {
          toast.error(data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
      .catch(error => {
        toast.error(error.response?.data?.message
          || 'Algo inesperado ocorreu. Por favor, tente novamente em alguns minutos.!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      })
      .finally(res => setSubmitting(false))
  }

  return (

    <Grid container>
      <Grid item xs={12} md={12} lg={12} className={`cardFormCustomRadius paddingTabsCustom`} >

        {/* <Typography component="h3" className={classes.title} color="secondary" >
          Inserir Créditos
        </Typography> */}

        {profileLoaded && !qrCode && <form style={{ minWidth: '100%' }}>
          <Grid item xs={12} md={12} lg={12} className={`cardFormCustomRadius paddingTabs`} style={{ border: 'none' }}>
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item xs={12}>

                {/* rua: response.data.street || payment.rua,
            numero: response.data.number || payment.numero,
            bairro: response.data.neighborhood || payment.bairro, */}

                {profile && <Grid item xs={12}>
                  <Typography className={classes.textPattern}>CPF / CNPJ: {profile.documento}</Typography>
                  <Typography className={classes.textPattern}>Nome: {profile.nome}</Typography>
                  <Typography className={classes.textPattern}>Endereço: {profile.rua}, {profile.numero}, {profile.bairro} - {profile.cep} </Typography>
                </Grid>}

                {(!profile || !profile.documento) &&
                  <Grid item xs={12}>
                    <Tooltip title="Informe o documento" placement="top-end">
                      <TextField
                        autoFocus
                        className={classes.input}
                        variant="outlined"
                        margin="normal"
                        inputProps={{ maxLength: 180 }}
                        required
                        fullWidth
                        value={payment?.documento}
                        error={errors?.documento}
                        id="documento"
                        label={<Typography className={classes.textPattern}>CPF / CNPJ</Typography>}
                        name="documento"
                        onChange={inputChangeHandler}
                      />
                    </Tooltip>
                  </Grid>
                }

                {(!profile || !profile.nome) &&
                  <Grid item xs={12}>
                    <Tooltip title="Informe o nome" placement="top-end">
                      <TextField
                        className={classes.input}
                        variant="outlined"
                        margin="normal"
                        inputProps={{ maxLength: 180 }}
                        required
                        fullWidth
                        value={payment?.nome}
                        error={errors?.nome}
                        id="nome"
                        label={<Typography className={classes.textPattern}>Nome</Typography>}
                        name="nome"
                        onChange={inputChangeHandler}
                      />
                    </Tooltip>
                  </Grid>
                }

                {(!profile || !profile.cep) &&
                  <Grid item xs={6}>
                    <Tooltip title="Informe o CEP" placement="top-end">
                      <TextField
                        className={classes.input}
                        variant="outlined"
                        margin="normal"
                        inputProps={{ maxLength: 180 }}
                        required
                        fullWidth
                        error={errors?.cep}
                        id="cep"
                        label={<Typography className={classes.textPattern}>CEP</Typography>}
                        name="cep"
                        onChange={inputChangeHandler}
                      />
                    </Tooltip>
                  </Grid>
                }

                <Grid container>
                  {(!profile || !profile.rua) &&
                    <Grid item xs={10} style={{ paddingRight: 10 }}>
                      <Tooltip title="Informe a rua" placement="top-end">
                        <TextField
                          className={classes.input}
                          variant="outlined"
                          margin="normal"
                          inputProps={{ maxLength: 180 }}
                          required
                          fullWidth
                          id="rua"
                          error={errors?.rua}
                          label={<Typography className={classes.textPattern}>Rua</Typography>}
                          name="rua"
                          onChange={inputChangeHandler}
                        />
                      </Tooltip>
                    </Grid>
                  }

                  {(!profile || !profile.numero) && <Grid item xs={2} style={{ paddingLeft: 10 }}>
                    <Tooltip title="Número" placement="top-end">
                      <TextField
                        className={classes.input}
                        variant="outlined"
                        margin="normal"
                        inputProps={{ maxLength: 180 }}
                        required
                        fullWidth
                        id="numero"
                        error={errors?.numero}
                        label={<Typography className={classes.textPattern}>Número</Typography>}
                        name="numero"
                        onChange={inputChangeHandler}
                      />
                    </Tooltip>
                  </Grid>
                  }
                </Grid>

                {/* <Grid container>
                  <Grid item xs={10} style={{ paddingRight: 10 }}>
                    <Tooltip title="Complemento" placement="top-end">
                      <TextField
                        className={classes.input}
                        variant="outlined"
                        margin="normal"
                        inputProps={{ maxLength: 180 }}
                        required
                        fullWidth
                        id="complemento"
                        label={<Typography className={classes.textPattern}>Complemento</Typography>}
                        name="complemento"
                        onChange={inputChangeHandler}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={2} style={{ paddingLeft: 10 }}>
                    <Tooltip title="Complemento" placement="top-end">
                      <TextField
                        className={classes.input}
                        variant="outlined"
                        margin="normal"
                        inputProps={{ maxLength: 180 }}
                        required
                        fullWidth
                        id="estado"
                        label={<Typography className={classes.textPattern}>Estado</Typography>}
                        name="estado"
                        onChange={inputChangeHandler}
                      />
                    </Tooltip>
                  </Grid>
                </Grid> */}

                {(!profile || !profile.bairro) &&
                  <Grid item xs={12}>
                    <Tooltip title="bairro" placement="top-end">
                      <TextField
                        className={classes.input}
                        variant="outlined"
                        margin="normal"
                        inputProps={{ maxLength: 180 }}
                        required
                        fullWidth
                        id="bairro"
                        error={errors?.bairro}
                        label={<Typography className={classes.textPattern}>Bairro</Typography>}
                        name="bairro"
                        onChange={inputChangeHandler}
                      />
                    </Tooltip>
                  </Grid>
                }

                {/* {(!profile || (!profile.cep && !profile.cidade)) && <Grid item xs={12}>
                  <Tooltip title="cidade" placement="top-end">
                    <TextField
                      className={classes.input}
                      variant="outlined"
                      margin="normal"
                      inputProps={{ maxLength: 180 }}
                      required
                      fullWidth
                      id="cidade"
                      label={<Typography className={classes.textPattern}>Cidade</Typography>}
                      name="cidade"
                      onChange={inputChangeHandler}
                    />
                  </Tooltip>
                </Grid>
                } */}

                <fieldset className={classes.fieldsetInput}>
                  <legend className={classes.legendPattern}>Valor</legend>
                  <IntlCurrencyInput
                    className={classes.removeConfigInput}
                    currency="BRL"
                    config={currencyConfig}
                    value={payment.valor_total}
                    id="valor_total"
                    name="valor_total"
                    onChange={inputChangeHandler}
                  />
                </fieldset>

              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={12} lg={12} >
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item>
                <Button
                  type="submit"
                  size='large'
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={(event) => { handlePayment(event) }}
                  className={classes.submit}
                >
                  {submitting ? (
                    <LineScalePulseOut
                      color={'#BA7100'}
                      loading={submitting}
                    />
                  ) : (
                    "Confirmar"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>

        </form>}

        {
          (!confirmed && qrCode) && <Grid style={{ textAlign: 'center' }}>
            {!qrCodeExpired && <img src={qrCode.img} style={{ width: '50%' }}></img>}
            <Grid item xs={12} md={12} lg={12}>
              {countdownDate &&
                <Countdown
                  date={countdownDate}
                  className={classes.countDownTime}
                  renderer={({ minutes, seconds, completed }) => {
                    if (completed) {
                      setQrCodeExpired(true)
                      // Render a completed state
                      return <>
                        <span style={{ color: '#000', fontSize: '22px', paddingTop: '20px', display: 'block' }}>Tempo expirado!</span>
                        <Button
                          type="submit"
                          size='large'
                          fullWidth
                          variant="contained"
                          color="primary"
                          style={{
                            marginTop: '40px'
                          }}
                          onClick={(event) => {
                            setQrCode(null)
                            setConfirmed(null)
                          }}
                          className={classes.submit}
                        >
                          Tentar novamente
                        </Button>
                      </>
                    } else {
                      // Render a countdown
                      return <span style={{ color: '#000', paddingTop: '15px', fontSize: '18px' }}>Expira em: {minutes}:{seconds}</span>;
                    }
                  }}
                />
              }
            </Grid>
            {!qrCodeExpired && <Typography className={classes.textPattern}
              style={{
                fontSize: '14px',
                color: 'black',
                marginTop: '20px'
              }}>{qrCode.key}</Typography>}
          </Grid>
        }

        {
          (confirmed && qrCode) && <Grid style={{ textAlign: 'center' }}>
            <Typography className={classes.textPattern}
              style={{
                fontSize: '22px',
                color: 'black',
                fontWeight: 600,
                marginTop: '20px',
                marginBottom: '20px',
              }}>Pagamento confirmado!</Typography>
          </Grid>
        }

      </Grid>
    </Grid>
  )
}