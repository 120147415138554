import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { LineScalePulseOut } from 'react-pure-loaders';
import SurveyCard from '../../../dashboard/components/surveyCard';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import GroupIcon from '@material-ui/icons/Group';
import LinkIcon from '@material-ui/icons/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { useParams } from 'react-router';

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
    fontWeight: 700,
    boxShadow: 'none'
  },
  input: {
    backgroundColor: '#fff',
  },
  titleText: {
    fontWeight: 700,
    fontSize: '2rem',
    color: '#000000'
  },
  margin: {
    margin: theme.spacing(1),
  },
  iconMoney: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 700,
    fontSize: '2rem',
    margin: theme.spacing(1),
  },
  iconTime: {
    fontWeight: 700,
    fontSize: '2rem',
    margin: theme.spacing(1),
  },
  withIcon: {
    display: 'flex',
    alignItems: 'center'
  },
  sizer: {
    display: 'flex',
    alignItems: 'flex-end',
    height: '2rem',
    margin: theme.spacing(1)
  },
  iconsPattern: {
    color: '#576B77',
    fontSize: '18px',
    marginRight: 10
  },
  textPattern: {
    color: '#576B77',
    fontSize: '15px',
    maxWidth: '100%',
    wordWrap: 'break-word'
  },
  textNames: {
    color: '#111640',
    fontSize: '18px',
    fontWeight: 600,
    maxWidth: '100%',
    wordWrap: 'break-word'
  },
  textPreview: {
    color: '#111640',
    fontSize: '18px',
    fontWeight: 600,
    maxWidth: '100%',
    margin: '15px auto'
  },
  elementsMargin: {
    marginBottom: '1.5em'
  }
}));

export default function PublishTab(props) {
  const user = props.user

  const classes = useStyles();
  const { id } = useParams();

  const [submitting, setSubmitting] = useState(false);
  const [research] = useState(props.newResearch);
  const [researchFinance] = useState(props.newResearchFinance);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (props.paymentRequired) {
      props.handleCanPaymentTabChange()
      props.handleChange(3)
      return
    }

    setSubmitting(true)
    await props.handlePublish(event)
    setSubmitting(false)
  }

  const handleCreate = async (event) => {
    event.preventDefault();

    setSubmitting(true)
    await props.handleCreate(event)
    setSubmitting(false)
  }

  const handleUpdate = async (event) => {
    event.preventDefault();

    setSubmitting(true)
    await props.handleUpdate(event)
    setSubmitting(false)
  }

  const valorAPagar = researchFinance?.totalPayAmount

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      sm={12} xs={12} md={12} lg={12}
    >
      <Grid item xs={12} md={12} lg={12} className={`cardFormCustomRadius paddingTabs`}>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="flex-start"
          className={classes.elementsMargin}
        >
          <Typography component="h3" className={classes.textNames} >
            {research?.name}
          </Typography>

          <Typography component="h3" className={classes.textPattern} >
            de {user?.name}
          </Typography>
        </Grid>

        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <LinkIcon className={classes.iconsPattern} />
          <Typography className={classes.textPattern}>
            {research?.link}
          </Typography>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <GroupIcon className={classes.iconsPattern} />
          <Typography className={classes.textPattern}>
            0/{researchFinance?.numRespondents}
          </Typography>
        </Grid>

        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          className={classes.elementsMargin}
        >
          <AttachMoneyIcon className={classes.iconsPattern} />
          <Typography className={classes.textPattern}>
            {new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(valorAPagar)}
          </Typography>
        </Grid>

        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.elementsMargin}
        >
          <Typography className={classes.textPreview}>
            Preview
          </Typography>
          <SurveyCard
            marginAuto={true}
            key={research?.id}
            id={research?.id}
            title={research?.name}
            time={researchFinance?.maximumResponseTime}
            endDate={researchFinance?.publishedUntil}
            budget={researchFinance?.payAmount}
            researcher={user?.name}
            instructions={research?.description}
            status={'PUBLISHED'}
            noLink={true}
            pathTypo={user?.isResearcher}
            expanded={true}
            xs={9}
            sm={7}
            md={6}
            lg={6}
          />
        </Grid>

      </Grid>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        className={'footerButtonsTabs'}
      >

        <Grid>
          <Button
            type="button"
            size='large'
            color="secondary"
            className={'buttonCancel'}
            onClick={() => props.handleChange(1)}
            startIcon={<NavigateBeforeIcon />}
          >
            Voltar
          </Button>
        </Grid>

        <Grid style={{ paddingRight: 10 }}>

        </Grid>
        <Grid style={{ paddingRight: 10 }}>
          <Button
            type="submit"
            size='large'
            fullWidth
            variant="contained"
            color="secondary"
            onClick={(event) => {
              if (!!id === false) {
                handleCreate(event)
              } else {
                handleUpdate(event)
              }
            }}
            className={classes.submit}
            endIcon={<NavigateNextIcon />}
            style={{ marginBottom: 0 }}
          >
            {submitting ? (
              <LineScalePulseOut
                color={'#BA7100'}
                loading={submitting}
              />
            ) :
              !!id === false ? "Criar" : "Salvar Alterações"
            }
          </Button>
          <Button
            type="submit"
            size='large'
            fullWidth
            variant="contained"
            color="primary"
            onClick={(event) => { handleSubmit(event) }}
            className={classes.submit}
            endIcon={<NavigateNextIcon />}
          >
            {submitting ? (
              <LineScalePulseOut
                color={'#BA7100'}
                loading={submitting}
              />
            ) : (
              "Publicar"
            )}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
