import React, { useState, useContext, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import logoBrand from '../../assets/logo-text-color.png';
import UserAvatar from "../UserAvatar";
import { Box, Button, Divider, Grid, useMediaQuery } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
import { UserContext } from '../../providers/UserProvider';
import { toast } from 'react-toastify';
import { headerReasearch, headerResponser } from '../../utils/utils';

export default function MenuAppBar(props) {

  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,

      position: 'relative',
      top: 0,
      width: '100%',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    appbar: {
      backgroundColor: '#fafafa',
    },
    title: {
      flexGrow: 1,
      textTransform: "capitalize",
      width: "fit-content",
      maxWidth: '210px',
      minWidth: '210px',
      paddingRight: 15,
    },
    logo: {
      width: '100%',
      margin: 'auto'
    },
    linkHeader: {
      color: "#111640!important",
      fontWeight: 300,
      padding: 5
    },
    iconCopy: {
      color: '#aeaeaf',
      padding: 8
    },
    novaPesquisa: {
      width: '165px',
      fontSize: '12px'
    }
  }));

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const location = useLocation();
  const matches = useMediaQuery('(max-width:600px)');

  const { userState, setIsLoading, logout } = useContext(UserContext);
  const user = userState

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleInsertCredits = () => {

  }

  const handleCopyCode = (e) => {
    e.preventDefault()
    try {
      navigator.clipboard.writeText(userState?.id)
      toast.info('Seu ID foi copiado.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (e) {
      console.log("Conexão não é https")
    }
  }

  const goToSurveyCreate = () => {
    history.push("/create-survey");
  };

  const handleSignOut = async (event) => {
    setAnchorEl(null);
    setIsLoading(true);
    logout()
    setTimeout(() => {
      setIsLoading(false)
      toast.info('Sessão encerrada com sucesso!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      history.push('/login')
    }, 1000)
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color={"inherit"} className={classes.appbar}>
        <Toolbar>
          <Box component="div" display='flex' className={classes.title}>
            <img src={logoBrand} className={classes.logo} alt="Bluesurvey" />
          </Box>

          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center">
            {!user?.administrator && user?.isResearcher && <Fragment>

              <Link to="/dashboard/ALL" className={`${classes.linkHeader} ${headerReasearch.includes(location.pathname) && location.pathname === '/dashboard/ALL' ? 'activated' : ''}`} style={{ marginRight: '10px' }}>
                PESQUISAS
              </Link>

            </Fragment>}

            {!user?.administrator && !user?.isResearcher && <Fragment>
              <Link to="/dashboard/PUBLISHED" className={`${classes.linkHeader} ${headerResponser.includes(location.pathname) && location.pathname === '/dashboard/PUBLISHED' ? 'activated' : ''}`} style={{ marginRight: '10px' }}>
                PUBLICADAS
              </Link>

              <Link to="/dashboard/ALL" className={`${classes.linkHeader} ${headerResponser.includes(location.pathname) && location.pathname === '/dashboard/ALL' ? 'activated' : ''}`} style={{ marginLeft: '10px', marginRight: '10px' }}>
                RESPONDIDAS
              </Link>
            </Fragment>}

            {!user?.administrator && <Link to="/financial-info" className={`${classes.linkHeader} ${headerReasearch.includes(location.pathname) && location.pathname === '/financial-info' ? 'activated' : ''}`} style={{ marginLeft: '10px' }}>
              FINANCEIRO
            </Link>}

            {user.administrator && <Link to="/admin-info" className={`${classes.linkHeader} ${headerReasearch.includes(location.pathname) && location.pathname === '/admin-info' ? 'activated' : ''}`} style={{ marginLeft: '10px' }}>
              ADMINISTRADOR
            </Link>}

          </Grid>
          {/*<SearchBar />*/}
          {user?.isResearcher ? (
            <div>
              <Button
                type="button"
                variant="contained"
                color="primary"
                className={classes.novaPesquisa}
                onClick={goToSurveyCreate}
                startIcon={<AddIcon />}
              >
                Nova pesquisa
              </Button>
            </div>) : (
            <div style={{ width: '170px' }}>

            </div>
          )}
          {!user?.isResearcher && !user.administrator ?
            <Fragment>
              {userState.id}
              <IconButton
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={e => handleCopyCode(e)}
                className={classes.iconCopy}
              >
                <FileCopyOutlinedIcon />
              </IconButton>
            </Fragment> : ''}
          {matches ? (<div></div>) : (
            <div>
              <IconButton
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <UserAvatar user={user} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
                <Link to="/profile">
                  <MenuItem onClick={handleClose}>
                    <Typography variant='body2'>
                      {user.name}
                    </Typography>
                  </MenuItem>
                </Link>
                <Divider />
                {/* {userState.isResearcher && <Fragment>
                  <Link to="/insert-credits">
                    <MenuItem onClick={handleInsertCredits}>
                      <Typography variant='body2'>
                        Inserir créditos
                      </Typography>
                    </MenuItem>
                  </Link>
                  <Divider />
                </Fragment>} */}
                <Link to="/login">
                  <MenuItem onClick={handleSignOut}>
                    <Typography variant='body2'>
                      Sair
                    </Typography>
                  </MenuItem>
                </Link>
              </Menu>
            </div>)
          }
        </Toolbar>
      </AppBar>
    </div>
  );
}