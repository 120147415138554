import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { toast } from 'react-toastify'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { LineScalePulseOut } from 'react-pure-loaders'
import EditIcon from '@material-ui/icons/Edit'
import { myProfileUser, myProfileUserUpdate } from '../../../../services/user'


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    padding: 10
  },
  button: {
    margin: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    fontWeight: 700
  },
  input: {

    backgroundColor: '#fff',
  },
  input_image: {
    display: 'none',
  },
  textPattern: {
    color: '#000000'
  },
}));

export default function BankTab(props) {
  const classes = useStyles()
  const [submitting] = useState(false)
  const [edit, setEdit] = useState(false)

  useEffect(() => {
    const getMyProfile = async () => {
      await myProfileUser()
        .then(response => {
          setAccount(response.data.account || '')
          setAgency(response.data.agency.slice(0, -1) || '')
          setDigit(response.data.agency.slice(-1))
          setBank(response.data.bank || '')
        })
    }

    getMyProfile()
  }, [])

  const [bank, setBank] = useState('');
  const [account, setAccount] = useState('');
  const [agency, setAgency] = useState('');
  const [digit, setDigit] = useState('');



  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      bank,
      account,
      agency: `${agency}${digit}`,
    }

    await myProfileUserUpdate(data)
      .then(response => {
        setEdit(false)
        if (response?.data.success) {
          toast.success(response?.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error('Algo inesperado ocorreu. Por favor, tente novamente em alguns minutos.!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch(error => {
        setEdit(false)
        toast.error('Algo inesperado ocorreu. Por favor, tente novamente em alguns minutos.!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .finally(() => {
        setEdit(false)
      })
  }


  return (
    <Grid container>
      <Grid item xs={12} md={12} lg={12} className='cardFormCustomRadius paddingTabsCustom'>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item ></Grid>
          <Grid item style={{ marginTop: 16 }}>
            <Button
              type="button"
              startIcon={<EditIcon />}
              onClick={e => setEdit(!edit)}
              color={edit ? `primary` : `secondary`}
            >
              Editar
            </Button>
          </Grid>
        </Grid>

        <form onSubmit={handleSubmit} noValidate>
          <Grid container alignItems="flex-start" spacing={2}>
            <Grid item xs={12} md={3} lg={3}>
              <TextField
                className={classes.input}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="bank"
                label={<Typography className={classes.textPattern}>Código do Banco</Typography>}
                name="bank"
                inputProps={{ maxLength: 3 }}
                disabled={edit ? false : true}
                onChange={event => setBank(event.target.value)}
                value={bank}
              />
            </Grid>

            <Grid item xs={12} md={2} lg={2}>
              <TextField
                className={classes.input}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="bank_agency"
                label={<Typography className={classes.textPattern}>Agência</Typography>}
                name="bank_agency"
                disabled={edit ? false : true}
                onChange={event => setAgency(event.target.value)}
                value={agency}
                inputProps={{ maxLength: 4 }}
              />
            </Grid>

            <Grid item xs={12} md={2} lg={2}>
              <TextField
                className={classes.input}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="bank_digit"
                label={<Typography className={classes.textPattern}>Dígito</Typography>}
                name="bank_digit"
                disabled={edit ? false : true}
                onChange={event => setDigit(event.target.value)}
                value={digit}
                inputProps={{ maxLength: 1 }}
              />
            </Grid>

            <Grid item xs={12} md={5} lg={5}>
              <TextField
                className={classes.input}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="bank_account"
                label={<Typography className={classes.textPattern}>Conta</Typography>}
                name="bank_account"
                disabled={edit ? false : true}
                onChange={event => setAccount(event.target.value)}
                value={account}
                inputProps={{ maxLength: 20 }}
              />
            </Grid>


            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item ></Grid>
              <Grid item style={{ marginTop: 16, paddingRight: 10 }}>
                <Button
                  type="submit"
                  size='large'
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={edit ? false : true}
                >
                  {submitting ? (
                    <LineScalePulseOut
                      color={'#BA7100'}
                      loading={submitting}
                    />
                  ) : (
                    "Concluir"
                  )}
                </Button>
              </Grid>
            </Grid>

          </Grid>
        </form>
      </Grid>
    </Grid>
  )
}