import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Logo from '../../assets/logo-text-color.png';

export default function Header() {

    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1,
            //marginBottom: theme.spacing(4),
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        appbar: {
            background: '#e6e6e6',
        },
        title: {
            flexGrow: 1,
            textTransform: "capitalize"
        }
    }));

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AppBar position="static" color={"inherit"} className={classes.appbar}>
            <Toolbar>
                <Link href="https://www.bluesurvey.com.br/" target="_self">
                    <Typography className={classes.title}>
                        <img src={Logo} alt='Bluesurvey' height="60"/>
                    </Typography>
                </Link>
            </Toolbar>
            </AppBar>
        </div>
    );
}