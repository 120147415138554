import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { LineScalePulseOut } from 'react-pure-loaders';
import logo from '../../../../assets/logo-text-color.png';
import Divider from '@material-ui/core/Divider';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { forgotPassword } from '../../../../services/user';
import { toast } from 'react-toastify';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    paper: {
        backgroundColor: '#E6E6E6',
    },
    paperMobile: {
        width: '100%',
        padding: theme.spacing(2),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    input: {
        backgroundColor: '#fff',
    },
    error: {
        marginTop: theme.spacing(2),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    divider: {
        marginBottom: 10
    },
    textPattern: {
        color: '#000000'
    },
    isSelected: {
        border: "2px solid #02afb8",
        borderRadius: 15,
        padding: "0.5em",
    },
    footerPaper: {
        marginBottom: theme.spacing(3)
    },
    nameInput: {
        textTransform: 'capitalize'

    }
}));

export default function ForgotPassword(props) {
    const classes = useStyles();
    const matches = useMediaQuery('(max-width:600px)');
    const registerForm = useRef(null);
    const [email, setEmail] = useState("");
    const [submitting, setSubmitting] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        setSubmitting(true);

        await forgotPassword(email).then(async (res) => {

            setSubmitting(false);
            toast.success('E-mail enviado para redefinir a sua senha.', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });


        }).catch(async (error) => {
            let errMessage = 'Algo inesperado ocorreu. Por favor, tente novamente em alguns minutos.'
            if (error.response.status === 500 && error.response.data.message)
                errMessage = error.response.data.message

            setSubmitting(false);
            toast.error(errMessage, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        })
            .finally(() => {
                setSubmitting(false);
            });

    };

    return (
        /*  <> */
        <Paper
            className={matches ? classes.paperMobile : classes.paper}
            elevation={matches ? 0 : 3}
        >
            <div className={classes.root}>
                <div>
                    <img src={logo} width={'100%'} alt="Bluesurvey" />
                </div>
                <form className={classes.form} noValidate onSubmit={handleSubmit} ref={registerForm} >
                    <TextField
                        className={classes.input}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email-register"
                        label={<Typography className={classes.textPattern}>E-mail</Typography>}
                        name="email"
                        autoComplete="email"
                        onChange={event => setEmail(event.target.value)}

                    />
                    <Button
                        type="submit"
                        size='large'
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        {submitting ? (
                            <LineScalePulseOut
                                color={'#BA7100'}
                                loading={submitting}
                            />
                        ) : (
                            "Recuperar senha"
                        )}
                    </Button>

                    <Button
                        type="submit"
                        size='large'
                        fullWidth
                        variant="contained"
                        color="secondary"
                        className={classes.submit}
                        onClick={props.voltar}
                    >
                        {submitting ? (
                            <LineScalePulseOut
                                color={'#BA7100'}
                                loading={submitting}
                            />
                        ) : (
                            "Voltar"
                        )}
                    </Button>

                    <Divider className={classes.divider} variant="middle" />
                    {/* <div className={classes.footerPaper}>
                        <ButtonFacebook />
                        <Box mt={1}></Box>
                        <ButtonGoogle />
                    </div> */}



                </form>
            </div>
        </Paper>
    );
}