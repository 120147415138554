import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { LineScalePulseOut } from 'react-pure-loaders';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { Tooltip } from '@material-ui/core';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  '& .MuiTextField-root': {
    margin: theme.spacing(1),
    width: '25ch',
  },
  avatarLg: {
    width: theme.spacing(20),
    height: theme.spacing(20),
    boxShadow: '0px 1px 11px -1px #555',
    marginBottom: 20,
    marginTop: 20,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    fontWeight: 600,
    boxShadow: 'none'
  },
  input: {

    backgroundColor: '#fff',
  },
  input_image: {
    display: 'none',
  },
  textPattern: {
    color: '#000000'
  },
  divRoot: {
    padding: 10
  },
  /* Styles applied to the `icon` and `label`'s wrapper element. */
  wrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'row',
  },
  fieldsetInput: {
    minWidth: '100%',
    backgroundColor: '#fff',
    animationName: 'onAutoFillCancel',
    border: '1px solid #c4c4c4',
    borderRadius: '4px',
    padding: '0 8px',
    '&:hover': {
      borderColor: 'rgba(0, 0, 0, 0.87)'
    },
  },
  fieldsetCustom: {
    border: '2px solid #30bcb6 !important'
  },
  legendPattern: {
    color: '#000000',
    fontSize: '11px',
    padding: '0 30px 0 6px'
  },
  removeConfigInput: {
    height: '100%',
    width: '100%',
    minWidth: '100%',
    border: 'none',
    fontFamily: 'Montserrat',
    color: '#333333',
    padding: '13.5px 10px',
  },
  textArea: {
    color: '#333333',
    minHeight: `90%`,
    minWidth: `100%`,
    height: `95%!important`,
    width: `100%`,
    maxHeight: `100%`,
    maxWidth: `100%`,
    padding: 5,
    marginTop: theme.spacing(1.2),
    overflow: "inherit!important"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
    maxWidth: "100%",
  },
  textPattern2: {
    textAlign: 'justify',
    color: '#576B77',
    fontWeight: 400,
    fontSize: '14px',
    maxWidth: '100%',
    wordWrap: 'break-word',
    margin: '0 auto 5px 0'
  },

}));

let schema = yup.object().shape({
  name: yup.string().required('Informe o título da pesquisa.'),
  description: yup.string().required('Informe as instruções.'),
  link: yup.string().required('Informe o link da pesquisa.').test('isValidUrl', 'Url inválida (ex.: https://google.com.br', function (value) {

    try {
      new URL(value);
      return true;
    }
    catch (error) {
      return false;
    }

  })
});

export default function SurveyTab(props) {
  console.log("NEW", props.newResearch)
  const classes = useStyles();
  const [research, setResearch] = useState(props.newResearch);
  /* const [value, setValue] = useState(0);  */
  const [submitting] = useState(false);
  const history = useHistory();

  useEffect(() => {

    if (props.newResearch) {
      setResearch(props.newResearch)
    }

  }, [props])

  const handleChange = (event, newValue) => {
    handleNewResearchChange(research)
    props.handleChange(newValue);
  };

  const handleNewResearchChange = (research) => {
    props.handleNewResearchChange(research);
  };

  const inputChangeHandler = (event) => {

    let str = event.target.value;
    let value = str.charAt(0).toUpperCase() + str.slice(1);

    let obj = { [event.target.name]: value }
    setResearch({ ...research, ...obj })
  };

  const handleSubmit = async (event) => {
    //Make a network call somewhere
    event.preventDefault();
    const data = {
      name: research?.name,
      description: research?.description,
      link: research?.link
    }
    const isValidForm = await schema.isValid(data)
    if (isValidForm) {
      props.handleCanFinanceTabChange()
      handleChange(event, 1)
    } else {

      let message = 'Campos não podem ser vazios!';
      let errors = await schema.validate(data)
        .catch((e) => {
          return e.errors;
        });

      toast.error(errors != null && errors.length > 0 ? errors[0] : message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  const handleCancel = (event) => {
    event.preventDefault();
    history.goBack();
  }

  const handleFocus = (event) => {
    event.preventDefault();
    event.target.parentElement.classList.add(classes.fieldsetCustom)
  }

  const handleBlur = (event) => {
    event.preventDefault();
    event.target.parentElement.classList.remove(classes.fieldsetCustom)
  }

  return (
    <Grid container>
      <form onSubmit={handleSubmit} noValidate>
        <Grid item xs={12} md={12} lg={12} className={`cardFormCustomRadius paddingTabs`}>
          <Grid container alignItems="flex-start" spacing={2}>
            <Grid item xs={12}>
              <Tooltip title="Servirá para identificar sua pesquisa ao longo do processo e ajudará o respondente a identificar o assunto que se refere a pesquisa." placement="top-end">
                <TextField
                  className={classes.input}
                  variant="outlined"
                  margin="normal"
                  inputProps={{ maxLength: 70 }}
                  required
                  fullWidth
                  value={research?.name || ''}
                  id="name"
                  label={<Typography className={classes.textPattern}>Título da pesquisa</Typography>}
                  name="name"
                  onChange={inputChangeHandler}
                />
              </Tooltip>
            </Grid>

            <Grid item xs={12}>
              <Tooltip title="Coloque todas as informações necessárias para o correto preenchimento da pesquisa." placement="top-end">
                <fieldset id="fieldsetDate" className={classes.fieldsetInput}>
                  <legend className={classes.legendPattern}>Instruções</legend>

                  <TextareaAutosize
                    aria-label="Instruções"
                    rowsMin="8"
                    name="description"
                    value={research?.description}
                    className={classes.removeConfigInput}
                    onChange={inputChangeHandler}
                    onFocus={(e) => handleFocus(e)}
                    onBlur={(e) => handleBlur(e)}
                  />
                </fieldset>
              </Tooltip>
            </Grid>

            <Grid item xs={12} style={{ marginBottom: '25px' }}>
              <Tooltip title="Coloque aqui o link da pesquisa para o respondente acessar sua pesquisa." placement="top-end">
                <TextField
                  className={classes.input}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  value={research?.link || ''}
                  id="link"
                  label={<Typography className={classes.textPattern}>Link da pesquisa</Typography>}
                  name="link"
                  onChange={inputChangeHandler}
                />
              </Tooltip>
              <Grid item className={classes.margin}>
                <Typography variant="body2" color="textSecondary" className={classes.textPattern2}>
                  <b>Importante!</b>
                </Typography>
              </Grid>
              <Grid item className={classes.margin}>
                <Typography variant="body2" color="textSecondary" className={classes.textPattern2}>
                  1. Colocar no seu formulário de coleta de dados a seguinte pergunta – “Insira abaixo seu código de pesquisador”. Este código ajudará a identificar os respondentes que realizaram a tarefa.
                </Typography>
              </Grid>
              <Grid item className={classes.margin}>
                <Typography variant="body2" color="textSecondary" className={classes.textPattern2}>
                  2. Não esqueça de, ao final da pesquisa, criar um código de verificação (até 4  letras e números da sua preferência). O respondente irá inserir ele no nosso sistema ao finalizar sua pesquisa. É ele que irá vincular os respondentes à sua pesquisa.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12} lg={12} className={'footerButtonsTabs'} >
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item >
              <Button
                className={'buttonCancel'}
                type="button"
                size='large'
                color="secondary"
                startIcon={<NavigateBeforeIcon />}
                onClick={handleCancel}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.submit}
                type="submit"
                size='large'
                fullWidth
                variant="contained"
                color="primary"
                endIcon={<NavigateNextIcon />}
              >
                {submitting ? (
                  <LineScalePulseOut
                    color={'#BA7100'}
                    loading={submitting}
                  />
                ) : (
                  "Dados da pesquisa"
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>

      </form>
    </Grid>

  );
}
