import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { grey } from '@material-ui/core/colors';
import ModalPolicy from '../Policy';
import { UserContext } from '../../providers/UserProvider';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '0%',
    boxShadow: '0px 0px 2px 0.45px'// set a sort of an edge among footer and main container
  },
  footer: {
    padding: '10.4px 15.2px 10.4px',
    backgroundColor: '#fff',
    position: 'fixed',
    bottom: 0,
    width: '100%',
    height: theme.spacing(5),
    minHeight: theme.spacing(5),
    maxHeight: theme.spacing(5),
    boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
  },

  innerFooter: {
    backgroundColor: '#fff',
    bottom: 0,
    width: '100%',
    boxShadow: '0px 0px 15px -2px rgba(217,217,217,1)'
  },
  insta: {
    paddingRight: 10,
    fontSize: 40,
    color: grey[400],
    '&:hover': {
      color: '#E3316C'
    }
  },
  facebook: {
    paddingRight: 10,
    fontSize: 40,
    color: grey[400],
    '&:hover': {
      color: '#1777F2'
    }
  },
  linkedin: {
    paddingRight: 10,
    fontSize: 40,
    color: grey[400],
    '&:hover': {
      color: '#0077B5'
    }
  },
  terms: {
    paddingRight: 10,
    color: '#576B77',
    fontSize: '14px',
    fontWeight: 400,

    '& a': {
      cursor: 'pointer'
    }
  },
  info: {
    textAlign: 'left',
    color: '#576B77',
    fontSize: '14px',
    fontWeight: 400
  },
  company: {
    paddingLeft: 10,
  }
}));

export default function Footer() {
  const classes = useStyles();
  const sourcePolicy = 'http://www.slideshare.net/slideshow/embed_code/key/78NW2yxg5UZMM3';
  const sourceTerms = 'https://www.slideshare.net/slideshow/embed_code/key/B6SfqHFrWhihpr';

  const { userState } = useContext(UserContext);

  const handleOpenLink = (link) => {
    window.open(link, '_self')
  }

  return (
    <div className={classes.root}>
      <footer className={classes.footer}>
        <div>
          <Grid
            container
            direction="row"
            justify={!userState.isAuthenticated ? "center" : "space-between"}
            alignItems="center"
          >
            <Grid item className={classes.info} style={{
              textAlign: 'center'
            }}>2021. Bluesurvey. Todos os direitos reservados.</Grid>

            {
              userState.isAuthenticated &&
              <Grid item className={classes.terms}>
                <Link onClick={() => { window.open('./politica.pdf', '_self') }} target='blank' variant='body1' color="inherit">
                  Política de privacidade
                </Link>
                &nbsp;&nbsp;
                <Link onClick={() => { window.open(userState.isResearcher ? './termos_pesquisador.pdf' : './termos_respondente.pdf') }} target='blank' variant='body1' color="inherit">
                  Termos de uso
                </Link>

              </Grid>
            }
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid
              item className={classes.company}
            >

            </Grid>

            <Grid
              item
              className={classes.media}
            >

            </Grid>
          </Grid>
        </div>
      </footer>
    </div>
  );
}